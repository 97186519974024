(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/color-helper/assets/javascripts/color-helper.js') >= 0) return;  svs.modules.push('/components/marketplace/color-helper/assets/javascripts/color-helper.js');

'use strict';

const colors = ['#9CAAD6',
'#D6B1A7',
'#92C6D6',
'#D6BE7C',
'#87D6BD',
'#BD87CB',
'#EADABD',
'#DBDBDB',
'#ECEE8A',
'#AFE78D' 
];

const getColor = number => colors[number % colors.length];
setGlobal('svs.components.marketplace.colorHelper.getColor', getColor);

 })(window);